<template>
  <div v-if="!item.hidden">
    <template
      v-if="hasOneShowingChild(item.children,item) && (!onlyOneChild.children||onlyOneChild.noShowingChildren)&&!item.alwaysShow">
      <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.routerPath, onlyOneChild.query)">
        <el-menu-item :class="{'submenu-title-noDropdown':!isNest, 'isV5': isV5}"
                      :index="resolvePath(onlyOneChild.routerPath)">
          <item v-if="!isV5 || !isCollapse" :icon="onlyOneChild.meta.icon||(item.meta&&item.meta.icon)"
                :title="onlyOneChild.meta.title"/>

          <div v-show="isV5 && isCollapse" class="slot-item">
            <item :icon="onlyOneChild.meta.icon||(item.meta&&item.meta.icon)" :title="onlyOneChild.meta.title"/>
          </div>
        </el-menu-item>
      </app-link>
    </template>

    <el-submenu v-else ref="subMenu" :class="{'isV5': isV5}" :index="resolvePath(item['routerPath'])"
                :popper-class="isV5 ? 'isV5-popper':''">
      <template slot="title">
        <item v-if="item.meta && !isV5" :icon="item.meta && item.meta.icon" :title="item.meta.title"/>
        <div v-if="item.meta && isV5" class="slot-subMenu">
          <item v-if="item.meta" :icon="item.meta && item.meta.icon" :title="item.meta.title"/>
        </div>
      </template>
      <sidebar-item
        v-for="(child, index) in item.children"
        :key="index"
        :base-path="resolvePath(child.routerPath)"
        :class="{'nest-menu-top':(child['level'] === 4 && !child['children'])}"
        :is-nest="true"
        :item="child"
        class="nest-menu"
      />
    </el-submenu>
  </div>
</template>

<script>
const path = require('path')
import {isExternal} from '@/utils/validate'
import Item from './Item'
import AppLink from './Link'
import FixiOSBug from './FixiOSBug'
import {mapGetters} from 'vuex'

export default {
  name: 'SidebarItem',
  components: {Item, AppLink},
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data() {
    this.onlyOneChild = null
    return {}
  },
  methods: {
    hasOneShowingChild(children = [], parent) {
      if (!children) {
        children = []
      }
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item
          return true
        }
      })

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = {...parent, path: '', noShowingChildren: true}
        return true
      }

      return false
    },

    resolvePath(routePath, routeQuery) {

      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(this.basePath)) {
        return this.basePath
      }

      if (routeQuery) {
        let query = JSON.parse(routeQuery)
        // return {path: path.resolve(this.basePath, routePath), query: query}
        // return {path: routePath, query: query}

      }
      return path.resolve('', routePath)
      // return routePath
    }
  },

  computed: {
    ...mapGetters(['sidebar']),
    isV5() {
      return this.$route.path.indexOf('V5') > -1
    },

    isCollapse() {
      return this.sidebar.opened
    },
  },
}
</script>

<style lang="scss" scoped>

::v-deep .el-submenu__title,
.el-menu-item {
  //padding-left: 20px !important;
}
</style>
