import Vue from 'vue'
import Router from 'vue-router'

import MonitorPlatform from '@/views/survey/monitor/MonitorPlatform.vue'
import ImagePage from '@/views/survey/monitor/ImagePage.vue'
import Gwzt from '@/views/survey/monitor/gwzt' // 管网状态监测
import Syj from '@/views/survey/monitor/syj' // 水源井水质监测
import Xdsb from '@/views/survey/monitor/xdsb' // 水源井水质监测
import Gsqs from '@/views/survey/monitor/gsqs' // 供水趋势分析
import Djlr from '@/views/survey/monitor/djlr' // 南安独居老人大屏改版

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

/**
 * Note: 路由配置项
 *
 * hidden: true                     // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true                 // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                  // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                  // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                  // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect             // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'               // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * query: '{"id": 1, "name": "ry"}' // 访问路由的默认传递参数
 * roles: ['admin', 'common']       // 访问路由的角色权限
 * permissions: ['a:a:a', 'b:b:b']  // 访问路由的菜单权限
 * meta : {
    noCache: true                   // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'                  // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'                // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false               // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/system/user'      // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */

// 公共路由
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login'),
    hidden: true
  },
  {
    path: '/register',
    component: () => import('@/views/register'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/error/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/error/401'),
    hidden: true
  },
  {
    path: '/account',
    component: () => import('@/views/account'),
    hidden: true,
    meta: {title: '账号绑定'}
  },
  {
    path: '/payCallback',
    component: () => import('@/views/WXIndependencePages/paymentSuccessfulPage'),
    hidden: true,
    meta: {title: '支付成功'}
  },
  {
    path: '/index',
    component: () => import('@/views/sso'),
    hidden: true
  },
  {
    path: "",
    component: Layout,
    redirect: "/index",
    children: [
      {
        path: '/personalPortal',
        component: () => import('@/views/personalPortal'),
        name: 'personalPortal',
        meta: {title: '个人门户', icon: 'dashboard', affix: true}
      }
    ]
  },
  {
    path: '/MonitorPlatform',
    component: MonitorPlatform,
    children: [
      {
        path: '/MonitorPlatform/:page',
        name: 'MonitorPlatform',
        component: ImagePage,
        props: true,
        // children: [
        //   {
        //     path: 'network',
        //     component: (resolve) => require(['@/views/pipeNetwork/network'], resolve),
        //     name: 'network',
        //     //hidden: true,
        //   }
        // ]
      },
      {
        path: '/gwzt',
        component: Gwzt,
      },
      {
        path: '/syj',
        component: Syj,
      },
      {
        path: '/xdsb',
        component: Xdsb,
      },
      {
        path: '/djlr',
        component: Djlr
      },
      {
        path: '/gsqs',
        component: Gsqs
      }
    ]
  },

  {
    path: '/digitalTwin/:page',
    name: 'DigitalTwin',
    component: ImagePage,
    props: true
  },
  // {
  //   path: '',
  //   // component: Layout,
  //   redirect: '/index',
  //   component: () => import('@/views/sso'),
  //   // children: [
  //   //   {
  //   //     path: '/index',
  //   //     component: () => import('@/views/sso'),
  //   //     name: 'Index',
  //   //     // meta: { title: '首页', icon: 'dashboard', affix: true }
  //   //   }
  //   // ]
  // },

  {
    path: '/user',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [
      {
        path: 'profile',
        component: () => import('@/views/system/user/profile/index'),
        name: 'Profile',
        meta: {title: '个人中心', icon: 'user'}
      }
    ]
  }
]

// 动态路由，基于用户权限动态去加载
export const dynamicRoutes = [
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['system:user:edit'],
    children: [
      {
        path: 'role/:userId(\\d+)',
        component: () => import('@/views/system/user/authRole'),
        name: 'AuthRole',
        meta: {title: '分配角色', activeMenu: '/system/user'}
      }
    ]
  },
  {
    path: '/system/role-auth',
    component: Layout,
    hidden: true,
    permissions: ['system:role:edit'],
    children: [
      {
        path: 'user/:roleId(\\d+)',
        component: () => import('@/views/system/role/authUser'),
        name: 'AuthUser',
        meta: {title: '分配用户', activeMenu: '/system/role'}
      }
    ]
  },
  {
    path: '/system/dict-data',
    component: Layout,
    hidden: true,
    permissions: ['system:dict:list'],
    children: [
      {
        path: 'index/:dictId(\\d+)',
        component: () => import('@/views/system/dict/data'),
        name: 'Data',
        meta: {title: '字典数据', activeMenu: '/system/dict'}
      }
    ]
  },
  {
    path: '/system/oss-config',
    component: Layout,
    hidden: true,
    permissions: ['system:oss:list'],
    children: [
      {
        path: 'index',
        component: () => import('@/views/system/oss/config.vue'),
        name: 'OssConfig',
        meta: {title: '配置管理', activeMenu: '/system/oss', icon: ''}
      }
    ]
  },
  {
    path: "/system/job-log",
    component: Layout,
    hidden: true,
    permissions: ['monitor:job:list'],
    children: [
      {
        path: 'index',
        component: () => import('@/views/revenue/system/job/log.vue'),
        name: 'JobLog',
        meta: {title: '调度日志', activeMenu: '/revenue/business/parameter/quatrz'}
      }
    ]
  },
  {
    path: '/tool/gen-edit',
    component: Layout,
    hidden: true,
    permissions: ['tool:gen:edit'],
    children: [
      {
        path: 'index/:tableId(\\d+)',
        component: () => import('@/views/tool/gen/editTable'),
        name: 'GenEdit',
        meta: {title: '修改生成配置', activeMenu: '/tool/gen'}
      }
    ]
  },
  {
    path: '/inspection/site-branch',
    component: Layout,
    hidden: true,
    permissions: ['inspection:site:list'],
    children: [
      {
        path: 'branch/:siteId(\\d+)',
        component: () => import('@/views/inspection/site/branch'),
        name: 'SiteBranch',
        meta: {title: '分支管理', activeMenu: '/inspection/site'}
      }
    ]
  },
  {
    path: '/inspection/dict-sublevelDict',
    component: Layout,
    hidden: true,
    permissions: ['inspection:dict:list'],
    children: [
      {
        path: 'sublevelDict/:dictId(\\d+)',
        component: () => import('@/views/inspection/dict/sublevelDict'),
        name: 'sublevelDict',
        meta: {title: '子集字典', activeMenu: '/inspection/dict'}
      }
    ]
  }
]

// 防止连续点击多次路由报错
let routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

export default new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({y: 0}),
  routes: constantRoutes
})
