<template>
  <div
    :class="classObj"
    :style="{ '--current-color': theme }"
    class="app-wrapper"
  >
    <div
      v-if="device === 'mobile' && sidebar.opened"
      class="drawer-bg"
      @click="handleClickOutside"
    />
    <div v-if="isShwBanner" :style="{paddingLeft: sidebar.opened ? '200px' : '68px'}"
         class="banner" :class="{'isV3': !isV5}">
      <div class="banner-logo-box">
        <img :src="bannerLogo" alt="" class="banner-logo-box-img"/>
      </div>
      <span>{{ homeTitle }}</span>
    </div>
    <sidebar v-if="!sidebar.hide" :class="{'sidebar-container-isShwBanner': isShwBanner}"
             class="sidebar-container"/>
    <div
      :class="{ hasTagsView: needTagsView, sidebarHide: sidebar.hide }"
      class="main-container"
    >
      <div :class="{ 'fixed-header': fixedHeader }">
        <navbar/>
        <tags-view v-if="needTagsView"/>
      </div>
      <app-main/>
      <right-panel>
        <settings/>
      </right-panel>
    </div>
  </div>
</template>

<script>
import RightPanel from '@/components/RightPanel'
import {AppMain, Navbar, Settings, Sidebar, TagsView} from './components'
import ResizeMixin from './mixin/ResizeHandler'
import {mapState} from 'vuex'
import variables from '@/assets/styles/variables.module.scss'
import Cookies from 'js-cookie'

export default {
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    RightPanel,
    Settings,
    Sidebar,
    TagsView
  },
  mixins: [ResizeMixin],

  computed: {
    ...mapState({
      theme: (state) => state.settings.theme,
      sideTheme: (state) => state.settings.sideTheme,
      sidebar: (state) => state.app.sidebar,
      device: (state) => state.app.device,
      needTagsView: (state) => state.settings.tagsView,
      fixedHeader: (state) => state.settings.fixedHeader,
      isShwBanner: state => state.settings.bannerIsShow && JSON.parse(Cookies.get('isShowBanner'))
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
    },
    variables() {
      return variables
    },

    isV5() {
      return this.$route.path.indexOf('V5') > -1
    },

    homeTitle() {
      return Cookies.get('homeTitle')
    },

    bannerLogo() {
      return Cookies.get('bannerLogo')
    }
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', {withoutAnimation: false})
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixin.scss';
@import '~@/assets/styles/variables.module';

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$base-sidebar-width});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 68px);
}

.sidebarHide .fixed-header {
  width: 100%;
}

.mobile .fixed-header {
  width: 100%;
}

@font-face {
  font-family: YouSheBiaoTiHei;
  src: url(~@/assets/fonts/YouSheBiaoTiHei.ttf);
}

.banner {
  width: 100%;
  font-family: YouSheBiaoTiHei;
  color: #505578;
  font-size: 35px;
  text-align: center;
  height: 74px;
  line-height: 74px;
  background-image: url(~@/assets/images/title-bg.png);
  background-color: #34469a;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;

  &.isV3 {
    background-image: url(~@/assets/images/title-bg-v3.png);
    background-color: #393A3C;
  }

  > div {
    height: 40px;
    display: flex;

    img {
      height: 100%;
    }
  }

  > span {
    letter-spacing: 15px;
    color: #61dcff;
    margin-left: 30px;
  }
}
</style>
