// 监测预警平台页面名称
const PLATFORM = 'MonitorPlatform'; // 在父组件中加载
const SINGLE_PAGE = 'digitalTwin'; // 独立页面
export const pages = {
  MONITOR: {title: '监测预警平台', value: 'monitor', frame: ''},
  BJJC: {title: '表具监测', value: 'bjjc', frame: PLATFORM},
  BJSJ: {title: '表具数据查询', value: 'bjsj', frame: PLATFORM},
  GWZT: {title: '管网状态监测', value: 'gwzt', frame: ''},
  SYJ: {title: '水源井水质监测', value: 'syj', frame: ''},
  XDSB: {title: '消毒设备监测', value: 'xdsb', frame: ''},
  DJLR: {title: '独居老人爱心监测', value: 'djlr', frame: ''},
  DJLRSJ: {title: '独居老人数据', value: 'djlrsj', frame: PLATFORM},
  GSQS: {title: '供水趋势分析', value: 'gsqs', frame: ''},
  SZLS: {title: '数字孪生平台', value: 'szls', frame: SINGLE_PAGE},
  BJJCTJ: {title: '表具监测统计', value: 'bjjctj', frame: SINGLE_PAGE},
  BJMLJC: {title: '表具命令监测', value: 'bjmljc', frame: SINGLE_PAGE},
  BJSJJC: {title: '表具数据监测', value: 'bjsjjc', frame: SINGLE_PAGE},
  BSDT: {title: '办事大厅', value: 'bsdt', frame: SINGLE_PAGE},
}

const frontBaseURL = process.env.VUE_APP_BASE_FRONT;

export const links = {
  YSGL: frontBaseURL + '/revenue/business/big/dataEchart/index', // 营收管理平台
  XXFB: frontBaseURL + '/cms/cmsnotice/waterCutOffIndex', // 信息发布平台
  YWGL: frontBaseURL + '/operation/operations/maintenance', // 运维管理平台
  SERVICEALL: 'http://36.112.134.105:8565/', // 办事大厅
  XTGL: frontBaseURL + '/manager/system/user', // 系统管理平台
  HTGL: frontBaseURL + '/survey/sur/salarmLogs', // 后台管理
  SSO_LOGIN: frontBaseURL + '/index' // 首页
};
