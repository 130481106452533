module.exports = {
  /**
   * 侧边栏主题 深色主题theme-dark，浅色主题theme-light
   */
  sideTheme: 'theme-dark',

  /**
   * 主题背景色，默认为浅色，深色主题dark，浅色主题light
   */

  wholeTheme: "light",

  /**
   * 是否系统布局配置
   */
  showSettings: false,

  /**
   * 是否显示顶部导航
   */
  topNav: true,

  /**
   * 是否显示 tagsView
   */
  tagsView: true,

  /**
   * 是否固定头部
   */
  fixedHeader: false,

  /**
   * 是否显示logo
   */
  sidebarLogo: true,

  /**
   * 是否显示动态标题
   */
  dynamicTitle: false,

  /**
   * 是否计量单位为升
   */
  waterUnit: false,

  /**全屏banner是否显示*/
  bannerIsShow: false,

  /**
   * @type {string | array} 'production' | ['production', 'development']
   * @description Need show err logs component.
   * The default is only used in the production env
   * If you want to also use it in dev, you can pass ['production', 'development']
   */
  errorLog: 'production',

  /*全局baseUrl*/
  globalBaseUrl: {
    NOTICE: 'notice',
    REVENUE: 'revenue',
    OPERATION: 'operation',
    PAYMENT: 'payment',
    CMS: 'cms',
    SYSTEM: 'system',
    V5: 'V5',
  }
}
