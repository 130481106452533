import request from '@/utils/request'

//获取短信配置模板列表
export function getSmsTemplateList(query) {
  return request({
    url: '/notice/message/template/list',
    method: 'get',
    params: query
  })
}

// 删除短信模板
export function delSmsTemplate(id) {
  return request({
    url: '/notice/message/template/' + id,
    method: 'delete'
  })
}

/*获取短信模板类型字典列表*/
export function getContractsDictType(isAll = false) {
  let url = isAll ? `/notice/v1/notice/dict/message/channel?listType=0` : `/notice/v1/notice/dict/message/channel`
  return request({
    url,
    method: 'get',
  })
}

/*获取短信模板状态字典列表*/ //通用状态
export function getContractsDictTempStatus(isAll = false) {
  let url = isAll ? `/notice/v1/notice/dict/common/status?listType=0` : `/notice/v1/notice/dict/common/status`
  return request({
    url,
    method: 'get',
  })
}

//消息渠道字典
export function getMessageChannel(isAll = false) {
  let url = isAll ? `/notice/v1/notice/dict/message/channel?listType=0`: `/notice/v1/notice/dict/message/channel`
  return request({
    url,
    method: 'get',
  })
}

//云渠道字典
export function getCloudChannel(isAll = false) {
  let url = isAll ? `/notice/v1/notice/dict/cloud/channel?listType=0`: `/notice/v1/notice/dict/cloud/channel`
  return request({
    url,
    method: 'get',
  })
}

/*新增或修改短信模板基础信息*/
export function addOrUpdateTemplateContractsInfo(data) {

  return request({
    url: `/notice/message/template`,
    method: 'post',
    data
  })
}

/*通过主键查询模板基础信息*/
export function getTemplateContractsInfoByTemplateId(templateId) {

  return request({
    url: `/notice/message/template/${templateId}`,
    method: 'get',
  })
}

/*编辑模板文件参数信息*/
export function updateTemplateContractsParams(data) {

  return request({
    url: `/notice/message/template/extend`,
    method: 'put',
    data
  })
}

