<template>
  <div class="img-wrapper">
    <!-- 顶部导航 -->
    <div class="top-nav flex">
      <router-link to="/monitorHome" style="height: 100%;">
        <span class="system-name">京源城乡供水一体化平台</span>
      </router-link>
      <ul class="flex-grow">
        <li><a :href="links.XTGL">系统管理</a></li>
        <li><a :href="links.XXFB">信息发布平台</a></li>
        <li><a :href="links.YWGL">运维管理平台</a></li>
        <li><a :href="links.YSGL">营收管理平台</a></li>
        <li>
          监测预警平台
          <ul class="sub-menu">
            <template v-for="(item, index) in list">
              <li :key="index" @click="redirect(item)">{{ item.title }}</li>
              <li class="sub" v-for="(subItem, subIndex) in item.children" :key="index + subIndex"
                  @click="redirect(subItem)">{{ subItem.title }}
              </li>
            </template>
            <li><a :href="links.HTGL">后台管理</a></li>
          </ul>
        </li>
        <li>
          <router-link :to="`/${pages.BSDT.frame}/${pages.BSDT.value}`">办事大厅</router-link>
        </li>
        <li>
          <router-link to="/digitalTwin/szls">三维可视化大屏</router-link>
        </li>
      </ul>
      <img class="avatar" src="~@/assets/survey/image/page-image/avatar.png" alt="">
      <a href="">退出 |
        <a :href="links.SSO_LOGIN">返回官网首页</a>
      </a>
    </div>
    <router-view class="image-page"></router-view>
  </div>
</template>
<script>
import {pages, links} from './monitorPage.js'

export default {
  props: ['page'],
  data() {
    return {
      pages,
      links,
      imgRoot: '~@/assets/survey/image/page-image/',
      list: [
        //Object.assign({ children: [pages.BJMLJC, pages.BJJCTJ] }, pages.BJSJJC),
        pages.GWZT,
        pages.SYJ,
        pages.XDSB,
        pages.DJLR,
        pages.GSQS
      ]
    }
  },
  methods: {
    redirect(to) {
      let path = `/${to.frame}/${to.value}`.replace(/^\/+/, '/');
      this.$router.replace(path);
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/style.scss';

.top-nav {
  justify-content: space-between;
  font-size: 18px;
  width: 100vw;
  height: 7vh;
  border-radius: 0px 0px 30px 30px;
  background-color: #00031b;
  background-image: linear-gradient(0deg, rgba(90, 106, 178, 0.78) 0%, #000F58 21%, rgba(15, 15, 15, 0.00) 100%);
  position: fixed;
  z-index: 7;

  img.logo {
    height: 95%;
    margin-left: 30px;
  }

  .system-name {
    @include ys-title;
    margin: 0px 0 0 60px;
    font-size: 28px;
    display: inline-block;
    line-height: 7vh;
  }

  > img.avatar {
    margin-left: 50px;
  }

  > a:last-child {
    margin: 0 72px 0 35px;

    &, & a {
      color: #2E81BE;
      text-decoration: none;
    }
  }

  > ul {
    list-style: none;

    > li {
      position: relative;
      padding: 14px 19px;
      float: right;
      color: #fff;
      text-shadow: 0px 4px 10px 0px #2E81BE;
      margin-right: 10px;

      &:hover {
        cursor: pointer;
        border-radius: 12px;
        background: radial-gradient(149% 100% at 50% 100%, rgba(103, 186, 255, 0.92) 0%, rgba(0, 106, 172, 0.11) 43%, rgba(0, 97, 168, 0.00) 100%);

        > ul.sub-menu {
          display: block;
        }
      }

      > a {
        text-decoration: none;
        color: #fff;
      }

      > ul.sub-menu {
        display: none;
        background-color: #0B205A;
        white-space: nowrap;
        position: absolute;
        list-style: none;
        padding: 0;
        top: 100%;
        left: 0;
        color: #51A2FC;
        font-size: 16px;
        line-height: 36px;
        padding: 10px 0;

        > li {
          padding: 0 30px;

          &::before {
            content: "·";
            margin-right: 20px;
          }

          &.sub::before {
            content: "";
            display: inline-block;
            width: 12px;
            height: 12px;
            background-image: url(~@/assets/survey/image/sub-item.png);
            background-repeat: no-repeat;
            margin: 0 8px 0 22px;
          }

          &:hover {
            background-color: #192B86;
          }
        }
      }
    }
  }
}

.img-wrapper {
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
}

::v-deep .image-page {
  img {
    min-width: 100%;
  }
}
</style>
